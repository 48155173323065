<template>
    <div class="animated fadeIn">
        <b-card class="card-border mt-4">
            <b-card-title><i class="fa fa-file-text"></i> Billing Report</b-card-title>
            <b-card-sub-title>Handles the generation of billing reports for all client accounts</b-card-sub-title>
            <div fluid class="px-2 mt-4">
                <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

                <!-- Filter  -->
                <b-row class="mt-2">
                    <b-col sm="12" md="3" lg="3">
                        <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                            class="filter">
                            FILTER OPTIONS
                        </b-button>
                    </b-col>

                    <b-col sm="12">
                        <!-- Collapsible Filter Options -->
                        <b-collapse id="collapse-1" class="mt-2">
                            <b-card>
                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date From" description="Reference to Billing Due Date">
                                            <b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en"
                                                reset-button label-reset-button="Clear"
                                                :date-format-options="dateFormatOptions" v-validate="'required'" />
                                            <span v-show="errors.has('Date From')" class="help-block">
                                                {{ errors.first('Date From') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Date To" description="Reference to Billing Due Date">
                                            <b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en"
                                                reset-button label-reset-button="Clear"
                                                :date-format-options="dateFormatOptions" v-validate="'required'" />
                                            <span v-show="errors.has('Date To')" class="help-block">
                                                {{ errors.first('Date To') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Asset Owner">
                                            <v-select name="Asset Owner" class="style-chooser" label="text"
                                                placeholder=" - Please select - " :options="allAssetOwnerOptions"
                                                :reduce="(company) => company.value" v-model="filterBy.assetOwner">
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a asset owner
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Client">
                                            <v-select name="Client" class="style-chooser" label="text"
                                                placeholder=" - Please select - " :options="allClientOptions"
                                                :reduce="(company) => company.value" v-model="filterBy.client">

                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a client
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Charge Type">
                                            <v-select name="Charge Type" class="style-chooser" label="text"
                                                placeholder=" - Please select - " :options="chargeTypeOptions"
                                                :reduce="(chargeType) => chargeType.value"
                                                v-model="filterBy.chargeType">

                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for a charge type
                                                    </em>
                                                </template>
                                            </v-select>
                                            <span v-show="errors.has('Charge Type')" class="help-block">
                                                {{ errors.first('Charge Type') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Status">
                                            <b-form-select name="Status" v-model="filterBy.status"
                                                :options="statusOptions" class="mr-2" />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Account No">
                                            <v-select name="Account No" class="style-chooser" label="text"
                                                placeholder=" - Please select - " :options="allClientAccountOptions"
                                                :reduce="(account) => account.value" v-model="filterBy.clientAccount">

                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        No results found for
                                                        <em>
                                                            <strong>{{ search }}</strong>
                                                        </em>
                                                    </template>
                                                    <em :style="{ opacity: 0.5 }" v-else>
                                                        Start typing to search for account no
                                                    </em>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="4" md="6" sm="12" class="mr-4">
                                        <b-form-group label="Billing Report No"
                                            description="NOTE: Input the exact Billing Report No to search">
                                            <b-form-input id="billingReportNo" name="Billing Report No" type="search"
                                                class="numFont" v-model="filterBy.billingReportNo"
                                                v-validate="{ regex: /^BR\d{13}$/ }" placeholder="BRXXXXXXXXXXXXX"
                                                autocomplete="off" />
                                            <span v-show="errors.has('Billing Report No')" class="help-block">
                                                {{ errors.first('Billing Report No') }}
                                            </span>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row no-gutters>
                                    <b-col sm="12">
                                        <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                            Search
                                        </b-button>
                                        <b-button class="mr-1" variant="primary" @click="resetFilters">
                                            Reset
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-collapse>
                    </b-col>
                </b-row>

                <!-- Select Actions and Items Per Page Options -->
                <b-row class="mt-4 mb-2">
                    <b-col sm="6" md="3">
                        <b-dropdown id="billing-report-select-actions" text=" Select Actions " variant="dark"
                            slot="append">
                            <b-dropdown-item v-b-modal.generate-billing-report v-show="!isViewer">
                                Generate Billing Report
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="xls"
                                    :name="fileName + '.xls'">
                                    Export Billing Reports in Excel
                                </json-excel>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <json-excel :data="exportData" :fields="exportFields" type="csv"
                                    :name="fileName + '.csv'">
                                    Export Billing Reports to CSV
                                </json-excel>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col sm="6" md="4" offset-md="5" class="text-md-right">
                        <b-input-group prepend="Show" append="/ Page">
                            <b-form-select :options="pageOptions" v-model="perPage" />
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-table ref="billingReportsTable" show-empty striped hover :items="items" :fields="fields"
                    :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

                    <template v-slot:cell(billingReportNo)="row">
                        <span class="numFont">
                            {{ row.item.billingReportNo }}
                        </span>
                    </template>

                    <template v-slot:cell(billingNumber)="row">
                        <span class="numFont">
                            {{ row.item.billingNumber }}
                        </span>
                    </template>

                    <template v-slot:cell(clientAccountNo)="row">
                        <span class="numFont">
                            {{ row.item.clientAccountNo }}
                        </span><br />
                        {{ row.item.client }}
                        <span v-if="row.item.principal && row.item.principal.length > 0">
                            | {{ row.item.principal }}
                        </span>
                    </template>

                    <template v-slot:cell(chargeType)="row">
                        <ChargeTypeStatus :billingReport="row.item" />
                    </template>

                    <template v-slot:cell(dueDate)="row">
                        <span class="text-nowrap">{{ getFormattedDate(row.item.dueDate) }}</span>
                    </template>

                    <template v-slot:cell(dateUpdated)="row">
                        <span class="text-nowrap">{{ getFormattedDate(row.item.dateUpdated) }}</span>
                    </template>

                    <template v-slot:cell(totalVATInclusiveAmount)="row">
                        <span class="numFont">
                            {{ formatMoneyValue('PHP', row.item.totalVATInclusiveAmount) }}
                        </span>
                    </template>

                    <template v-slot:cell(status)="row">
                        <BillingReportRowStatus :row="row" />
                    </template>

                    <template v-slot:cell(actions)="row">
                        <BillingReportRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isManager="isManager"
                            :isViewer="isViewer" />
                    </template>

                    <template slot="row-details" slot-scope="row">
                        <BillingReportDetailsView :row="row" />
                    </template>
                </b-table>

                <b-row>
                    <b-col md="8" sm="12" class="my-1">
                        <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
                    </b-col>
                    <b-col md="4" sm="12" class="my-1">
                        <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                            class="my-0" />
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <!-- Modals here -->
        <CancelBillingReport />
        <ConfirmBillingReportApproval :allClientAccountsObj="allClientAccountsObj" @onConfirm="updateTable" />
        <GenerateBillingReport :allAssetOwnerOptions="allAssetOwnerOptions" :allClientOptions="allClientOptions"
            :allClientAccountsObj="allClientAccountsObj" />
        <PrintBillingReport :allCompaniesObj="allCompaniesObj" :allUsersObj="allUsersObj"
            :allClientAccountsObj="allClientAccountsObj" />
    </div>
</template>

<script>
// Components
import CancelBillingReport from './billingReport/CancelBillingReport.vue';
import ConfirmBillingReportApproval from './billingReport/ConfirmBillingReportApproval.vue';
import BillingReportDetailsView from './billingReport/BillingReportDetailsView.vue';
import BillingReportRowActions from './billingReport/BillingReportRowActions.vue';
import BillingReportRowStatus from './billingReport/BillingReportRowStatus.vue';
import ChargeTypeStatus from './billingReport/ChargeTypeStatus.vue';
import GenerateBillingReport from './billingReport/GenerateBillingReport.vue';
import PrintBillingReport from './billingReport/PrintBillingReport.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// API & DAO
import billingReportApi from '@/api/billingReportApi';
import billingReportDAO from '@/database/billingReports';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import JsonExcel from 'vue-json-excel';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import _ from 'lodash';

export default {
    name: 'billing-report',
    components: {
        CancelBillingReport,
        ConfirmBillingReportApproval,
        BillingReportDetailsView,
        BillingReportRowActions,
        BillingReportRowStatus,
        ChargeTypeStatus,
        GenerateBillingReport,
        PrintBillingReport,
        Loading,
        JsonExcel,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'billingReportNo',
                    sortable: true,
                },
                {
                    key: 'billingNumber',
                    label: 'Billing No',
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: 'clientAccountNo',
                    label: 'Account No',
                    sortable: true,
                },
                {
                    key: 'chargeType',
                    sortable: false,
                    class: 'text-center'
                },
                {
                    key: 'dueDate',
                    sortable: true,
                },
                {
                    key: 'dateUpdated',
                    sortable: true,
                },
                {
                    key: 'totalVATInclusiveAmount',
                    label: 'Total Amount',
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'status',
                    class: 'text-center'
                },
                {
                    key: 'actions',
                    thClass: 'text-center'
                }
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            dateFormatOptions: { ...config.dateFormatOptions },

            allAssetOwnerOptions: [],
            allClientOptions: [],
            allClientAccountOptions: [],
            chargeTypeOptions: [],
            statusOptions: config.billingReportStatus,

            allCompaniesObj: {},
            allConnectedCompaniesObj: {},
            allConnectionsObj: {},
            allAssetTypesObj: {},
            allUsersObj: {},
            allClientAccountsObj: {},

            allBillingReportsObj: {},
            selBillingReport: {},

            defaultFilterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                assetOwner: { ...config.companyDefaultValue },
                client: { ...config.companyDefaultValue },
                chargeType: 'Rental',
                status: null,
                clientAccount: { ...config.clientAccountDefaultValue },
                billingReportNo: ''
            },

            filterBy: {
                dateFrom: moment().format('YYYY-MM-DD'),
                dateTo: moment().format('YYYY-MM-DD'),
                assetOwner: { ...config.companyDefaultValue },
                client: { ...config.companyDefaultValue },
                chargeType: 'Rental',
                status: null,
                clientAccount: { ...config.clientAccountDefaultValue },
                billingReportNo: ''
            },
            prevFilterBy: {},

            // Billing Report Params Field
            params: {},

            isSuperAdmin: this.$store.getters.isSuperAdmin,
            isViewer: this.$store.getters.isViewer,
            isManager: this.$store.getters.isManager,
            loggedUser: this.$store.getters.loggedUser,
            loggedUserCompany: this.$store.getters.loggedUserCompany,
            // Check for loader
            isLoading: false,

            // Listener
            billingReportListener: null,
        };
    },
    computed: {
        dateFrom() {
            const dateFrom = moment().add(-30, 'days');
            return dateFrom.format('YYYY-MM-DD');
        },
        dateTo() {
            const dateTo = moment().add(30, 'days');
            return dateTo.format('YYYY-MM-DD');
        },

        /**
         * Returns the set of data to be included in the export. For now this just
         * returns the data as is.
         *
         * @returns {Array} the set of data to be included in the export.
         */
        exportData() {
            return this.items;
        },

        /**
         * Derives the field information based from the data table configuration.
         *
         * @returns {object} the fields to be included in the export.
         */
        exportFields() {
            return {
                'Billing Report No': 'billingReportNo',
                'Billing No': 'Billing Number',
                'Account No': 'clientAccountNo',
                'Charge Type': 'chargeType',
                'Due Date': 'Due Date',
                'Date Updated': 'Date Updated',
                'Total VAT IN Amount': 'totalVATInclusiveAmount',
                'Status': 'status',
                'Start Date': 'Start Date',
                'End Date': 'End Date',
                'Asset Owner': 'assetOwner',
                'Client': 'client',
                'Principal': 'principal',
                'Charge Details': 'Charge Details',
                'Total Charge Amount': 'totalChargeTypeAmount',
                'Total Adjustment': 'totalAdjustmentAmount',
                'Total VAT EX Amount': 'totalVATExclusiveAmount',
                '12% VAT': 'total12PercentVATAmount',
                'Remarks': 'remarks',
                'Date Created': 'Date Created',
                'Created By': 'Created By',
                'UpdatedBy': 'Updated By',
                'Date Approved': 'Date Approved',
                'Approved By': 'Approved By',
                'Date Cancelled': 'Date Cancelled',
                'Cancelled By': 'Cancelled By',
                'Date Rejected': 'Date Rejected',
                'Rejected By': 'Rejected By',
            };
        },

        fileName() {
            let currTimeStamp = DateUtil.getCurrentTimestamp();
            return 'Billing-Reports-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
        },
    },
    created() {
        // Set Default Date Range
        this.defaultFilterBy.dateFrom = this.dateFrom;
        this.defaultFilterBy.dateTo = this.dateTo;
    },
    mounted() {
        setTimeout(async () => {
            try {
                // Filter Access
                if (this.$store.getters.isScanner || this.$store.getters.isRetailer) {
                    this.$router.push('/dashboard');
                    this.$toaster.warning('You are not allowed to access this page.');
                }

                // show loading indicator
                this.isLoading = true;

                this.updateParams();

                await this.retrieveData();
            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }

        }, config.timeout);

        // Event Listeners
        EventBus.$on('onCloseSaveBillingReport', (reportObj) => {
            this.updateTable(reportObj);
        });
    },
    methods: {
        listenerCallback(type, reportObj) {
            if ((!this.allBillingReportsObj[reportObj.id] && type === "added") || type === "modified") {
                this.allBillingReportsObj[reportObj.id] = reportObj;
                this.filterBillingReports(this.allBillingReportsObj);
            }
        },
        updateParams() {
            // init parameters
            this.params = this.$store.getters.billingReportParams;

            if (!_.isEmpty(this.params) && this.hasCompleteParams(this.params)) {

                // reset filter to default
                this.filterBy = { ...this.params.filterBy };

                this.allCompaniesObj = this.params.allCompaniesObj;
                this.allConnectedCompaniesObj = this.params.allConnectedCompaniesObj;
                this.allConnectionsObj = this.params.allConnectionsObj;
                this.allUsersObj = this.params.allUsersObj;
                this.allClientAccountsObj = this.params.allClientAccountsObj;
            } else {

                // reset filter to default
                this.filterBy = { ...this.defaultFilterBy };

                this.allCompaniesObj = { ...this.$store.getters.companies };
                this.allConnectedCompaniesObj = { ...this.$store.getters.connectedCompanies };
                this.allConnectionsObj = { ...this.$store.getters.connections };
                this.allUsersObj = { ...this.$store.getters.users, ...this.$store.getters.connectedUsers };
                this.allClientAccountsObj = { ...this.$store.getters.clientAccounts };
            }

            // Asset Owner Options
            this.allAssetOwnerOptions = this.getAssetOwnerOptions();

            // Client Options
            if (this.isSuperAdmin) {
                this.allClientOptions = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
            } else {
                this.allClientOptions = DropDownItemsUtil.retrieveActiveCompanies(this.allConnectedCompaniesObj);
                this.allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };
            }

            // Charge Type Options
            this.chargeTypeOptions = DropDownItemsUtil.retrieveChargeTypes(this.allClientAccountsObj);

            // Client Account Options
            this.allClientAccountOptions = DropDownItemsUtil.retrieveClientAccounts(this.allClientAccountsObj, false);
        },
        getAssetOwnerOptions() {
            let filteredCompaniesObj = {};

            // filter by subcription to billing
            filteredCompaniesObj = _.filter(this.allCompaniesObj, o => {
                return o.permissions && o.permissions.billing;
            });

            if (!this.isSuperAdmin) {
                // filter by company access if company view
                let companyId = this.loggedUserCompany.id;
                filteredCompaniesObj = _.filter(filteredCompaniesObj, o => {
                    return o.id === companyId || o.parentCompanyId === companyId;
                });
            }

            return DropDownItemsUtil.retrieveAssetOwners(filteredCompaniesObj);
        },
        hasCompleteParams(params) {
            if (
                !params.filterBy ||
                !params.allCompaniesObj ||
                !params.allConnectedCompaniesObj ||
                !params.allConnectionsObj ||
                !params.allUsersObj ||
                !params.allClientAccountsObj
            ) {
                return false;
            }

            return params.fromAddBillingReport || params.fromEditBillingReport;
        },

        updateTable(reportObj) {
            if (!_.isEmpty(reportObj)) {
                this.allBillingReportsObj[reportObj.id] = reportObj;
                this.filterBillingReports();
            }
        },

        validateFilter() {
            let isValid = true;

            if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
                this.$toaster.warning('Date From and Date To are required.');
                isValid = false;
            } else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
                (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
                this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
                isValid = false;
            } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
                this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
                isValid = false;
            } else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
                this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
                isValid = false;
            }

            return isValid;
        },
        async onFilterRequest() {
            let isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.$toaster.warning('Please address the field/s with invalid input.');
                // hide loading indicator
                this.isLoading = false;
                return;
            }

            if (!this.validateFilter()) {
                return;
            }

            if (!_.isEqual(this.filterBy, this.prevFilterBy)) {
                await this.retrieveData();
                this.prevFilterBy = { ...this.filterBy };
            }
        },
        async resetFilters() {
            if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
                // reset to default
                this.filterBy = { ...this.defaultFilterBy };
                this.prevFilterBy = { ...this.filterBy };

                // reset validation
                this.$validator.reset();
                this.errors.clear();

                await this.retrieveData();
            }
        },
        async retrieveData() {
            try {
                // show loading indicator
                this.isLoading = true;

                let filter = { ...this.filterBy };
                filter.companyId = this.loggedUserCompany.id;
                filter.fromTimestamp = DateUtil.startDateTimeStamp(new Date(filter.dateFrom));
                filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

                if (this.isSuperAdmin) {
                    const { data } = await billingReportApi.getBillingReports(
                        filter,
                        config.view.ADMIN,
                        this.loggedUser.id
                    );
                    this.allBillingReportsObj = { ...data.billingReports };
                } else {
                    const { data } = await billingReportApi.getBillingReports(
                        filter,
                        config.view.COMPANY,
                        this.loggedUser.id
                    );

                    this.allBillingReportsObj = { ...data.billingReports };
                }
                this.filterBillingReports();

                // Update listener
                this.billingReportListener = billingReportDAO.getReportListener(filter, this.listenerCallback);

            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
        filterBillingReports() {
            let filteredObjs = { ...this.allBillingReportsObj };

            _.forEach(this.allBillingReportsObj, (billingReport, id) => {
                this.filterByCompany(filteredObjs, billingReport, id);

                let client = this.filterBy.client;
                if (client && client.id && client.id !== billingReport.clientId) {
                    delete filteredObjs[id];
                }

                let chargeType = this.filterBy.chargeType;
                if (chargeType && chargeType.length > 0 && chargeType !== billingReport.chargeType) {
                    delete filteredObjs[id];
                }

                let status = this.filterBy.status;
                if (status && status.length > 0 && status !== billingReport.status) {
                    delete filteredObjs[id];
                }

                let clientAccount = this.filterBy.clientAccount;
                let accountNo = clientAccount.accountNo ? clientAccount.accountNo : '';
                if (accountNo && accountNo.length > 0 && accountNo !== billingReport.clientAccountNo) {
                    delete filteredObjs[id];
                }

                let billingReportNo = this.filterBy.billingReportNo;
                if (billingReportNo && billingReportNo.length > 0 && billingReportNo !== billingReport.billingReportNo) {
                    delete filteredObjs[id];
                }
            });

            this.processBillingReports(filteredObjs)
        },
        filterByCompany(filteredObjs, billingReport, id) {
            let companyIdFilter = this.filterBy.assetOwner.id;
            let assetOwnerId = billingReport.assetOwnerId;
            if ((companyIdFilter && companyIdFilter !== assetOwnerId) || !UserUtil.hasCompanyAccess(this.allCompaniesObj, assetOwnerId)) {
                delete filteredObjs[id];
            }
        },
        processBillingReports(billingReports) {
            this.items = Object.values(billingReports);
            this.items = _.sortBy(this.items, ['dateUpdated']);
            this.items.reverse();

            this.items.forEach((item) => {
                // Parse Date
                item['Start Date'] = this.getFormattedDate(item.startDate);
                item['End Date'] = this.getFormattedDate(item.endDate);
                item['Due Date'] = this.getFormattedDate(item.dueDate);

                // Parse Date and Time
                item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
                item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
                item['Date Approved'] = this.getFormattedDateWithTime(item.dateApproved);
                item['Date Rejected'] = this.getFormattedDateWithTime(item.dateRejected);
                item['Date Cancelled'] = this.getFormattedDateWithTime(item.dateCancelled);

                // Users Display
                item['Created By'] = UserUtil.getUserDisplay(this.allUsersObj, item.createdBy);
                item['Updated By'] = UserUtil.getUserDisplay(this.allUsersObj, item.updatedBy);
                item['Approved By'] = UserUtil.getUserDisplay(this.allUsersObj, item.approvedBy);
                item['Rejected By'] = UserUtil.getUserDisplay(this.allUsersObj, item.rejectedBy);
                item['Cancelled By'] = UserUtil.getUserDisplay(this.allUsersObj, item.cancelledBy);

                item['Billing Number'] = "'" + item.billingNumber;
                item['Charge Details'] = BillingReportUtil.getChargeTypeDetails(item);
            });
            this.totalRows = this.items.length;
            this.$store.dispatch('updateAllBillingReports', this.allBillingReportsObj);

            // remove show details
            _.forEach(this.items, item => {
                delete item._showDetails;
            });

            // refresh table
            if (this.$refs.billingReportsTable) {
                this.$refs.billingReportsTable.refresh();
            }

            this.updateBillingReportParams();
        },

        updateBillingReportParams() {
            this.params = {
                filterBy: this.filterBy,
                allCompaniesObj: this.allCompaniesObj,
                allConnectedCompaniesObj: this.allConnectedCompaniesObj,
                allConnectionsObj: this.allConnectionsObj,
                allUsersObj: this.allUsersObj,
                allClientAccountsObj: this.allClientAccountsObj,
            };

            this.$store.dispatch('setBillingReportParams', this.params);
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        },
        formatMoneyValue(currency, number) {
            return BillingReportUtil.formatMoneyValue(currency, number);
        }
    },
    beforeUnmount() {
        if (this.billingReportListener != null) {
            // Stop listening to changes
            this.billingReportListener();
        }
    },
    beforeDestroy() {
        EventBus.$off('onCloseSaveBillingReport');
    },
}
</script>

<style scoped></style>